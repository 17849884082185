import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HomeComponent} from './components/home/home.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {AboutUsComponent} from './components/about-us/about-us.component';
import {SupportUsComponent} from './components/support-us/support-us.component';
import {HowItWorksComponent} from './components/how-it-works/how-it-works.component';
import {RouteComponent} from './components/route/route.component';
import {LoginComponent} from './components/login/login.component';
import {EditOfferComponent} from './components/edit-offer/edit-offer.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MailApiClient, OfferApiClient, UsersApiClient} from "../api";
import {HttpClientModule} from "@angular/common/http";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {UserManagementComponent} from './components/user-management/user-management.component';
import {ContactComponent} from './components/contact/contact.component';
import {ImpressComponent} from './components/impress/impress.component';
import {RepairCafeComponent} from './components/repair-cafe/repair-cafe.component';
import {AngularEditorModule} from "@kolkov/angular-editor";
import {OfferListComponent} from './components/offer-list/offer-list.component';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {registerLocaleData} from '@angular/common';
import {AlbumComponent} from './components/album/album.component';
import {DataPrivacyComponent} from './components/data-privacy/data-privacy.component';
import {LightboxModule} from "ngx-lightbox";
import {RepairCafeDatesComponent} from './components/repair-cafe-dates/repair-cafe-dates.component';
import {loginRedirector} from "./login-redirector.service";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectsComponent,
    AboutUsComponent,
    SupportUsComponent,
    HowItWorksComponent,
    RouteComponent,
    LoginComponent,
    EditOfferComponent,
    UserManagementComponent,
    ContactComponent,
    ImpressComponent,
    RepairCafeComponent,
    OfferListComponent,
    AlbumComponent,
    DataPrivacyComponent,
    RepairCafeDatesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatFormFieldModule,
    MatProgressBarModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MatNativeDateModule,
    MatDatepickerModule,
    LightboxModule,
    FontAwesomeModule
  ],
  providers: [
    UsersApiClient,
    OfferApiClient,
    MailApiClient,
    loginRedirector,
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: "de-DE"}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
