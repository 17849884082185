<h1>Wo und wie finden Sie uns?</h1>
<div class="map-grid">
  <img alt="Standortkarte" src="./assets/karte.jpg"
       srcset="./assets/karte.webp, ./assets/karte-2x.webp 2x, ./assets/karte.png, ./assets/karte-2x.png 2x"
       style="width: 100%">
  <!--      <img style="width: 100%;"-->
  <!--      src="https://maps.geoapify.com/v1/staticmap?style=osm-liberty&width=1050&height=700&center=lonlat:9.838999,53.602932&zoom=15.2207&marker=lonlat:9.843227116293718,53.60526331454008;color:%23ff0000;size:medium|lonlat:9.837234049833796,53.60151316961068;color:%23083ead;size:medium|lonlat:9.83528150116922,53.599908718191216;color:%231fad08;size:medium&scaleFactor=2&apiKey=9f069ff293f041f8befecd8f502ae225"-->
  <h3>Unsere Verkaufsstellen</h3>
  <div class="addressContainer">
    <span class="title">
      <fa-icon [icon]="faMapMarker" size="lg" style="color: #ff0000"></fa-icon>
      Glücksgriff Lornsenstraße 86</span>
    <address>
      <a href="tel:04084055220">Tel. 040 840 55 220</a><br/>
      22869 Schenefeld<br/>
    </address>
    <div class="opening-times">
      <b class="spacer"></b>
      Öffnungszeiten:
      <table>
        <tr>
          <td>Montag bis Samstag</td>
          <td>10-13 Uhr</td>
        </tr>
        <tr>
          <td>Dienstag bis Freitag</td>
          <td>15-18 Uhr</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="addressContainer"><span class="title">
    <fa-icon [icon]="faMapMarker" size="lg" style="color: #083ead"></fa-icon>
    Glücksgriff Stadtzentrum Schenefeld</span>
    <address>
      Kiebitzweg 2<br/>
      1. Obergeschoss<br/>
      22869 Schenefeld<br/>
      <a href="tel:015235705504">Tel. 0152 357 055 04</a><br/>
    </address>
    <div class="opening-times">
      Öffnungszeiten:
      <table>
        <tr>
          <td>Donnerstag und Samstag</td>
          <td>10-13 Uhr</td>
        </tr>
        <tr>
          <td>Freitag</td>
          <td>15-18 Uhr</td>
        </tr>
      </table>
    </div>
  </div>
  <h3>Unsere Warenannahmestellen</h3>


  <div class="addressContainer"><span class="title">
    <fa-icon [icon]="faMapMarker" size="lg" style="color: #083ead"></fa-icon>
    Glücksgriff Stadtzentrum Schenefeld</span>
    <address>
      Kiebitzweg 2<br/>
      Erdgeschoss, von der Parkplatzseite aus<br/>
      22869 Schenefeld<br/>
      <a href="tel:015235705504">Tel. 0152 357 055 04</a><br/>
    </address>
    <div class="opening-times">
      Öffnungszeiten:
      <table>
        <tr>
          <td>Donnerstag und Samstag</td>
          <td>10-13 Uhr</td>
        </tr>
        <tr>
          <td>Freitag</td>
          <td>15-18 Uhr</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="addressContainer">
    <span class="title">
      <fa-icon [icon]="faMapMarker" size="lg" style="color: #ff0000"></fa-icon>
      Glücksgriff Lornsenstraße 86</span>
    <address>
      <a href="tel:04084055220">Tel. 040 840 55 220</a><br/>
      22869 Schenefeld<br/>
    </address>
    <div class="opening-times">
      <b class="spacer"></b>
      Öffnungszeiten:
      <table>
        <tr>
          <td>Montag bis Samstag</td>
          <td>10-13 Uhr</td>
        </tr>
        <tr>
          <td>Dienstag bis Freitag</td>
          <td>15-18 Uhr</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<p>
  Wir freuen uns, dass unser Angebot auch eine wirkliche Unterstützung für Menschen – insbesondere Familien – mit
  geringem Einkommen ist
  ermöglicht. Unser Glücksgriff ist und bleibt auch weiterhin ein sozialer Treffpunkt!<br/>
  Besuchen Sie uns!
</p>
<app-album [album]="album"></app-album>
