import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {IAlbumImage} from "../album/album.component";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {

  readonly album: IAlbumImage[] = [
    {
      caption: "",
      src: "/route/DSC_0002.JPG"
    }
  ]
  readonly faMapMarker = faMapMarkerAlt;

  constructor(titleService: TitleService) {
    titleService.setTitle("Standorte")
  }

  ngOnInit(): void {
  }
}
