import {Component, OnInit} from '@angular/core';
import {links} from './app-routing.module';
import {LoginService} from "./services/login.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Glücksgriff';
  currentYear = new Date().getFullYear();
  menuLinks = links;
  loggedIn = false;
  menuOpen: boolean = false;
  menuClosed: boolean = false;
  private toolbarElement: HTMLElement | undefined;
  private logoImage: HTMLImageElement | undefined;
  private menu: HTMLMenuElement | undefined;
  private readonly _loginService: LoginService;

  constructor(loginService: LoginService, router: Router) {
    this._loginService = loginService;
    loginService.loginStatus$
      .subscribe(s => this.loggedIn = s === "loggedIn");
    router.events.subscribe(() => this.menuOpen = false);
  }

  logout() {
    // noinspection JSIgnoredPromiseFromCall
    this._loginService.logout();
  }

  ngOnInit(): void {
    this.toolbarElement = document.getElementById("toolbar")!;
    this.logoImage = document.getElementById("logo")!.children[0] as HTMLImageElement;
    this.menu = document.getElementsByTagName("menu")[0] as HTMLMenuElement;
    this._setToolbarHeight();
    window.addEventListener("scroll", () => this._setToolbarHeight(), {passive: true});
    window.addEventListener("resize", () => this._setToolbarHeight(), {passive: true});
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.menuClosed = !this.menuOpen;
    this._setToolbarHeight();
  }

  private _setToolbarHeight() {
    if (window.outerWidth > 610) {
      this.menuClosed = false;
      this.menuOpen = false;
    }
    if (!this.toolbarElement || !this.logoImage)
      return;
    const maxToolbarHeight = this.toolbarElement.clientHeight;
    const menuHeight = this.menu?.clientHeight ?? 50;
    const margin = maxToolbarHeight - window.scrollY;
    const top = Math.max(-(maxToolbarHeight - menuHeight), -window.scrollY);
    this.toolbarElement.style.top = top + "px";
    if (margin <= menuHeight) {
      this.toolbarElement.setAttribute("scroll-y", window.scrollY.toString());
      this.logoImage.style.height = menuHeight + "px";
    } else {
      this.toolbarElement.classList.remove("hover");
      this.logoImage.style.height = margin + "px";
    }
  }
}
